import React from 'react'
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
const Video = () => {
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
  return (
    <div className="container">
    <div className="ourBlog">
      <div className="titleFont">
        <h3 className="text-color-primary fs-2">
            Videos
        </h3>
      </div>
      <div>
        <div className="beffect">
          <Slider {...settings} className="row">
        
            <Col xl={4}>
              <div className="ourBlogCard" data-aos="fade-up">
                <Card>
                  <Card.Body className="p-0">
                    <div className="blogCardBody">
                      <Card.Text>
                        <iframe height="315" width="100%" src="https://www.youtube.com/embed/CTHU63q3bVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col xl={4}>
              <div className="ourBlogCard" data-aos="fade-up">
                <Card>
                  <Card.Body className="p-0">
                    <div className="blogCardBody">
                      <Card.Text>
                        <iframe height="315" width="100%" src="https://www.youtube.com/embed/CTHU63q3bVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col xl={4}>
              <div className="ourBlogCard" data-aos="fade-up">
                <Card>
                  <Card.Body className="p-0">
                    <div className="blogCardBody">
                      <Card.Text>
                        <iframe height="315" width="100%" src="https://www.youtube.com/embed/CTHU63q3bVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
           
          </Slider>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Video