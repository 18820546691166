import React from 'react'
import { Col, Row } from 'react-bootstrap'
import star from '../../../assets/Images/star.png'

const Desc = () => {
    const advantages = [
        "Fondateur de Massinart, site e-commerce leader en vente de tableaux décoratifs",
        "Fondateur & CEO de RICHMEDIA, agence marketing",
        "DSI à KINDY INFO MAROC",
        "IT ARCHITECT PRESTIGIA.COM",
        "IT MANAGER TIME EARNING SOLUTIONS"
        ]
  return (
    <div>
        <div className="container mt-5 pt-5">
            <h3>
                Qui est Salah Eddine MIMOUNI ?
            </h3>
            <Row className='mt-3'>
                {advantages.map((advantage,index) => (
                <Col xs={12} md={12} key={index}>
                <div className="d-flex justify-content-start align-items-center mt-2 text-start">
                    <img src={star} className="mx-2" alt="" width="25px" />
                        {advantage}
                    </div>
                </Col>
                ))}
            </Row>
        </div>
    </div>
  )
}

export default Desc