import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/logo.png";

import { useState } from "react";
const Header = () => {
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className="header sticky-top">
        <Navbar expand="lg" collapseOnSelect>
          <div className="container">
            <div className="inner-header">
              <Navbar.Brand href="/">
               <img src={ThemeLogo} alt="Salah Eddine mimouni" width="124px" />
              </Navbar.Brand>
              <Button
                className="btn-navbar navbar-toggle"
                onClick={ToggleSidebar}
              >
                <i className="fa fa-bars toogleMenu" aria-hidden="true"></i>
              </Button>
              <div className={`sidebar ${isOpen === true ? "active" : " "}`}>
                <div id="mySidenav" className="sidenav menu-vertical">
                  <div className="close-nav hidden-md hidden-lg hidden-xl  ">
                    <div>
                      <span>Menu</span>
                    </div>
                    <div>
                      <Button
                        className="closebtn pull-right"
                        onClick={ToggleSidebar}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>
                  </div>
                  <div className="navDiv">
                    <Nav className="justify-content-center" style={{ flex: 1 }}>
                      <Nav.Link as={Link} to="/" className="text-uppercase">Accueil</Nav.Link> 
                      <Nav.Link as={Link} to="/a-propos" className="text-uppercase">à propos</Nav.Link>
                      <Nav.Link as={Link} to="/events" className="text-uppercase">événements</Nav.Link>
                      <Nav.Link as={Link} to="/articles" className="text-uppercase">Articles</Nav.Link>
                      <Nav.Link as={Link} to="/contact" className="text-uppercase">Contact</Nav.Link>
                    </Nav>
                  </div>
                </div>
              </div>
              <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
