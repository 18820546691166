import React from 'react'
import {Container , Row, Col} from 'react-bootstrap';
import Salah from "../../../assets/Images/SalahEddine.jpeg"
import '../../../theme/css/Presentation.css'
const Presentation = () => {
 
  return (
    <section className='my-5'>
      <div className="desc">
        <Container>
          <Row>
          <Col md={6} className="mt-5 pe-5 text-justify position-relative">
              <div className='bg-background-cover'>
              </div>
              <div>
                <img src={Salah} width="400px" className="imgSalah"/>
              </div>
            </Col>
            <Col md={6} className="mt-5 pe-5 text-justify">
              <h3 className='title mb-3'>
                Présentation
              </h3>
              <p className='mt-5'>
                  Avec plus de 12 ans d’expérience auprès d’entreprises innovantes à l’échelle internationale
                  Un amoureux du digital avec toute sa richesse et ses défis. Cette passion lui a permis d’être à 
                  la tête de deux projets qui a vu grandir : 
                  L’agence digitale RICHMEDIA & le site E-commerce Massinart.
                  Son expertise en E-commerce était la clé de réussite d’un grand nombre de startups et 
                  sites E-commerce qui ont bénéficié de son accompagnement.
                  Créatif et ambitieux, Salah veille toujours à délivrer les meilleurs 
                  conseils pour ses clients dans leur stratégie digitale.                
              </p>
               
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Presentation