import React from 'react'
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'

const Contact = () => {
  return (
    <div className="contactez py-5">
        <Container className='py-5'>
            <Row className="justify-content-center align-items-center">
                <Col md={6}>
                    <h5 className="text-bold text-capitalize text-color-primary fs-3">
                        Contacter salah eddine mimouni          
                    </h5>
                    <p className='mt-5 text-white'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, vel sed. Voluptate, repellat natus saepe facilis, iure, beatae tenetur itaque error earum sequi quisquam officiis excepturi! Aliquid illum et ratione magnam veniam mollitia, doloribus architecto at, autem accusamus placeat. Quasi.
                    </p>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Control
                        type="text"
                        placeholder="Nom & Prénom"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Control
                        type="text"
                        placeholder="Téléphone"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                        type="text"
                        placeholder="Email"
                        required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="message">
                        <Form.Control as="textarea" placeholder="Votre Message" rows={3} />
                    </Form.Group>
                    <button className='btn btn-contact'>
                        Contactez
                    </button>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Contact