import React from 'react'
import { Col, Row } from 'react-bootstrap'
import '../../../theme/css/part1.css'
const Part3 = () => {
  return (
    <div className='part3'>
        <div className="container">
        <Row>
            <Col md={6}>
                <p>
                    What gets me out of bed in the morning is not money or fame or my dog licking my face, but more importantly, the satisfaction of benefiting others.
                </p>
                <p>
                    I do what I do because I love helping people and companies leverage trends to create our exciting future.
                </p>
               
            </Col>
            <Col md={6}>
                
            </Col>
        </Row>            
        </div>
    </div>
  )
}

export default Part3