import React from 'react'
import {Container , Row, Col} from 'react-bootstrap';
import "../../../theme/css/Skills.css"
import google from '../../../assets/Images/Skills/Logo_Google_Analytics.svg.png'
import googleAdsWords from '../../../assets/Images/Skills/google ad word.png'
import facebookBluePrint from '../../../assets/Images/Skills/facebook blue print.png'
import emailing from '../../../assets/Images/Skills/emailing.png'
import linkedin from '../../../assets/Images/Skills/linkedin ads.png'
import php from '../../../assets/Images/Skills/php.png'
import Prestashop from '../../../assets/Images/Skills/Prestashop.png'
import wordpress from '../../../assets/Images/Skills/WordPress-logotype-wmark.png'
import seo from '../../../assets/Images/Skills/seo-logo.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css'; 
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const Skills = () => {
  return (
    <>
    <section className="skills">
      <div className=' pt-5 skillsOverLay'>
      <Container>
        <div className="text-center">
            <h3 className='title titleSkills text-dark'>
              Skills
            </h3>
        </div>
          <Row className="justify-content-between">
          <Swiper
      spaceBetween={10}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={3}
    >
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={google} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={googleAdsWords} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={facebookBluePrint} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={emailing} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={linkedin} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={php} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={Prestashop} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={wordpress} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="d-flex justify-content-center align-items-center">
          <img src={seo} width="100%" className="skillsimage" alt="Skills"/>
        </div>
      </SwiperSlide>
    </Swiper>
            
          </Row>
       </Container>
      </div>
   
        </section>
      
    </>
  )
}

export default Skills