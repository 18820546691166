import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import seminaire from '../../../assets/Images/seminaire.jpeg'
import Slider from "react-slick";

const Seminaires = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="py-5">
      <Container>
        <div className="text-center my-5">
            <h3 className='title titleSkills'>
              Prochains événements
            </h3>
            <p className='fs-5 text-secondary'>
              Taking Responsibility Regulation of Influencers
            </p>
        </div>
        <section>
        <Slider {...settings} className="row">
          <Col md={12} className="d-flex">
            <Col md={2}>
              <div className="fw-bold fs-4">
                <div className="mainDate fw-bold">
                  SEPT
                </div>
                <p className="d-block fw-bold">
                  02
                </p>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <img src={seminaire} width="100%"/>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <h4 className='fw-bold fs-3'>
                  Sigma Balkans & cis
                </h4>
                <h6 className="">
                  Sept 02-03
                </h6>
                <span className="text-color-primary">
                  Belgrade
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error modi praesentium excepturi ad magni nesciunt tempora vitae dolores quaerat libero voluptatibus officia eaque, cum velit explicabo voluptatum nostrum porro quia dolorum sit. Esse minus modi asperiores voluptatibus officia vero consequuntur ex expedita quidem provident tenetur ab sequi, vitae quis! Eum autem consectetur soluta perspiciatis et quis illum sint eius adipisci alias molestiae voluptas at, recusandae ut sapiente dolorum ratione exercitationem libero modi iure quae, illo incidunt, eveniet nisi! Saepe officiis aliquam quae doloremque facere dolor ducimus mollitia, quidem eveniet accusantium deserunt eum aperiam
                </p>
                <button className='btn btn-primary btn-main'>
                  Lire Plus de détails
                  <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>
                </button>
              </div>
            </Col>
          </Col>
          <Col md={12} className="d-flex">
            <Col md={2}>
              <div className="fw-bold fs-4">
                <div className="mainDate fw-bold">
                  SEPT
                </div>
                <p className="d-block fw-bold">
                  02
                </p>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <img src={seminaire} width="100%"/>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <h4 className='fw-bold fs-3'>
                  Sigma Balkans & cis 1
                </h4>
                <h6 className="">
                  Sept 02-03
                </h6>
                <span className="text-color-primary">
                  Belgrade
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error modi praesentium excepturi ad magni nesciunt tempora vitae dolores quaerat libero voluptatibus officia eaque, cum velit explicabo voluptatum nostrum porro quia dolorum sit. Esse minus modi asperiores voluptatibus officia vero consequuntur ex expedita quidem provident tenetur ab sequi, vitae quis! Eum autem consectetur soluta perspiciatis et quis illum sint eius adipisci alias molestiae voluptas at, recusandae ut sapiente dolorum ratione exercitationem libero modi iure quae, illo incidunt, eveniet nisi! Saepe officiis aliquam quae doloremque facere dolor ducimus mollitia, quidem eveniet accusantium deserunt eum aperiam
                </p>
                <button className='btn btn-primary btn-main'>
                  Lire Plus de détails
                  <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>
                </button>
              </div>
            </Col>
          </Col>
          <Col md={12} className="d-flex">
            <Col md={2}>
              <div className="fw-bold fs-4">
                <div className="mainDate fw-bold">
                  SEPT
                </div>
                <p className="d-block fw-bold">
                  02
                </p>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <img src={seminaire} width="100%"/>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <h4 className='fw-bold fs-3'>
                  Sigma Balkans & cis
                </h4>
                <h6 className="">
                  Sept 02-03
                </h6>
                <span className="text-color-primary">
                  Belgrade
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius error modi praesentium excepturi ad magni nesciunt tempora vitae dolores quaerat libero voluptatibus officia eaque, cum velit explicabo voluptatum nostrum porro quia dolorum sit. Esse minus modi asperiores voluptatibus officia vero consequuntur ex expedita quidem provident tenetur ab sequi, vitae quis! Eum autem consectetur soluta perspiciatis et quis illum sint eius adipisci alias molestiae voluptas at, recusandae ut sapiente dolorum ratione exercitationem libero modi iure quae, illo incidunt, eveniet nisi! Saepe officiis aliquam quae doloremque facere dolor ducimus mollitia, quidem eveniet accusantium deserunt eum aperiam
                </p>
                <button className='btn btn-primary btn-main'>
                  Lire Plus de détails
                  <i className="fa fa-angle-right mx-2" aria-hidden="true"></i>
                </button>
              </div>
            </Col>
          </Col>
        </Slider>
        </section>
      </Container>
    </section>  
  )
}

export default Seminaires