import React from 'react'
import videoBg from '../../../assets/Images/background.mp4'
import "../../../theme/css/BannerVideo.css"
import {Container , Row, Col} from 'react-bootstrap';


const BannerVideo = () => {
  return (
    <div className='main'>
      <div className="overlay"></div>
    <video src={videoBg} autoPlay loop muted />
      <div className="content">
      <Container>
          <Row>
            <Col md={8}>
              <h1 className='text-white titleName mt-5'>
                salah eddine mimouni 
              </h1>
              <h5 className='text-white titleNameSub'>
                Expert en marketing digital - conférencier
              </h5>
              <p className='contenuDesc my-5'>
                  Mon cœur de métier est d'accompagner les entreprises et les institutions dans la mise en place de stratégies digitales
                  S'adapter aux nouveaux médias pour créer des solutions numériques innovantes.
              </p>
              <button className='btn btn-primary voirPlus'>
                Voir Plus
              </button>
              </Col>
            </Row>
          </Container>   
      </div>
    </div>
  )
}

export default BannerVideo