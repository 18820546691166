import React from "react";
import Banner from "../HomePage/Banner"
import '../../../theme/css/Style.css'
import Experiences from "./Experiences";
import Presentation from "./Presentation";
import Skills from "./Skills";
import Seminaires from "./Seminaires";
import Refernces from "./Refernces";
import Contact from "./Contact";
import BannerVideo from "./BannerVideo";
import {Container , Row, Col} from 'react-bootstrap';
import Desc from "./Desc";
import Salah from "./Salah";

export default function Home() {
  return (
    <>
      <BannerVideo/>
      <Presentation />
      <Desc />
      <Salah />
      <Skills />
      <Seminaires />
      {/* <Refernces /> */}
      <Contact />
    </>
  );
}
