import React from 'react'
import { Col, Row } from 'react-bootstrap'
import '../../../theme/css/part1.css'
const Part1 = () => {
  return (
    <div className='part1'>
        <div className="container ">
        <Row>
            <Col md={6}>
                <p>
                    When I am on a conference stage or presenting virtually, I often see proverbial light-bulbs go on over the heads of audience members as they suddenly rush to jot something down.
                </p>
                <p>
                    I am thrilled to say they are not taking notes about what I am saying, but rather they are capturing their own ideas, inspired by the new trends they are discovering.
                </p>
                <p>
                    Whether I’m facilitating an online workshop for an exclusive group of business leaders or presenting an innovation keynote to thousands, I feel blessed to be in a position to provoke so much professional creativity.
                </p>
            </Col>
            <Col md={6}>

            </Col>
        </Row>            
        </div>
    </div>
  )
}

export default Part1