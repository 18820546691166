import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./theme/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DefultScrollToTop from "../src/components/ScrollToTop/DefultScrollToTop";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <DefultScrollToTop />
    <App />
  </BrowserRouter>,
  rootElement
);

reportWebVitals();
