import React from 'react'
import videoBg from '../../../assets/Images/background.mp4'
import "../../../theme/css/BannerVideo.css"
import {Container , Row, Col} from 'react-bootstrap';


const Banner = () => {
  return (
    <div className='main'>
    <div className="overlay"></div>
    <video src={videoBg} autoPlay loop muted />
    <div className="content">
    <Container>
        <Row>
          <Col md={8}>
            <h1 className="text-white titleName">Meet salah eddine mimouni</h1>
            <h5 className="text-white mt-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              necessitatibus harum dicta et minus autem inventore doloremque
            </h5>
            </Col>
          </Row>
         </Container>   
    </div>
</div>
  )
}

export default Banner