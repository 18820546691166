import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import blog1 from "../../../assets/Images/Blog/blog1.png";

const Article = () => {
  return (
    <>
    <div className='mainBanner'>
        <h1 className='text-white text-center'>
          Articles
        </h1>
    </div>
    <div className="container my-5 py-5">
      <Row>
        <Col xl={4}>
            <div className="ourBlogCard" data-aos="fade-up">
              <Card>
                <div className="bimg">
                  <Card.Img
                    className="img-fluid mx-auto"
                    variant="top"
                    src={blog1}
                  />
                </div>
                <Card.Body>
                  <div className="blogCardBody">
                    <div className="d-inline ourBlogContain">
                      <span>14 March 2022</span>
                    </div>
                    <hr />
                    <Card.Title>
                      Secure active living depend son repair day ladies
                      now.
                    </Card.Title>
                    <Card.Text>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since to 00s,
                      when an unknown printer.
                    </Card.Text>
                    <button className='btn btn-contact'>
                        lire la suite
                    </button>
                    
                  </div>
                </Card.Body>
              </Card>
            </div>
        </Col>
        <Col xl={4}>
            <div className="ourBlogCard" data-aos="fade-up">
              <Card>
                <div className="bimg">
                  <Card.Img
                    className="img-fluid mx-auto"
                    variant="top"
                    src={blog1}
                  />
                </div>
                <Card.Body>
                  <div className="blogCardBody">
                    <div className="d-inline ourBlogContain">
                      <span>14 March 2022</span>
                    </div>
                    <hr />
                    <Card.Title>
                      Secure active living depend son repair day ladies
                      now.
                    </Card.Title>
                    <Card.Text>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since to 00s,
                      when an unknown printer.
                    </Card.Text>
                    <button className='btn btn-contact'>
                        lire la suite
                    </button>
                    
                  </div>
                </Card.Body>
              </Card>
            </div>
        </Col>
        <Col xl={4}>
            <div className="ourBlogCard" data-aos="fade-up">
              <Card>
                <div className="bimg">
                  <Card.Img
                    className="img-fluid mx-auto"
                    variant="top"
                    src={blog1}
                  />
                </div>
                <Card.Body>
                  <div className="blogCardBody">
                    <div className="d-inline ourBlogContain">
                      <span>14 March 2022</span>
                    </div>
                    <hr />
                    <Card.Title>
                      Secure active living depend son repair day ladies
                      now.
                    </Card.Title>
                    <Card.Text>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since to 00s,
                      when an unknown printer.
                    </Card.Text>
                    <button className='btn btn-contact'>
                        lire la suite
                    </button>
                    
                  </div>
                </Card.Body>
              </Card>
            </div>
        </Col>
      </Row>
    </div>
  </>
  )
}

export default Article