import React from 'react'
import { Row } from 'react-bootstrap'
import image from "../../../assets/Images/SALAH PNG.png"
const Salah = () => {
  return (
    <div>
        <div className="container my-5 py-5">
            <Row className="align-items-center">
                <div className="col-md-5">
                    <h3 className='title mb-3 fs-1'>
                        Salah-Eddine Mimouni
                    </h3>
                    <p className='mt-5'>
                        “Je reçois énormément d’appels, de dm et d’emails de personnes me disant que je les ai inspirés à créer une entreprise, de monter un projet e-commerce, ou que mes conseils leur ont été d'une grande utilité.
                        Rien ne me rend aussi heureux.”
                    </p>
                </div>
                <div className="col-md-7 text-end">
                    <img src={image} alt="" />
                </div>
            </Row>
        </div>
    </div>
  )
}

export default Salah