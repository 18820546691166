import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "./Banner";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part3 from "./Part3";

const About = () => {
  return (
    <>
      <Banner />
      <Part1 />
      <Part2 />
      <Part3 />
    </>
  );
};

export default About;
