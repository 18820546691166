import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/pages/HomePage/Home";
import About from "../components/pages/Propos/About";
import Events from "../components/pages/Events/Events.jsx"
import Article from "../components/pages/Articles/Article"
import Contact from "../components/pages/Contact/Contact";

export default function Index() {
 
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/events" element={<Events />} />
        <Route path="/articles" element={<Article />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
     </div>
  );
}
