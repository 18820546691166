import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/logo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox  d-flex justify-content-between">
            <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img src={ThemeLogo} alt="Salah Eddine mimouni" width="124px" />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the dummy text ever
                  since the 1500s.Lorem Ipsum is simply dummy text of the
                  printing.
                </p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Menu
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutus">ACCUEIL</Link>
                    </li>
                    <li>
                      <Link to="/planing">À PROPOS</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">ÉVÉNEMENTS</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">ARTICLES</Link>
                    </li>
                    <li>
                      <Link to="/contact">CONTACT</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Contact Us 
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker mx-1"></i>
                    <span>
                      60, 29th Street, San Francisco Centre, United States.
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt mx-1"></i>
                    <span>0261-12345</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope mx-1"></i>
                    <span>demo@demo.com</span>
                  </div>
                </div>
                </Collapse>
                <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2022 . All Rights Reserved</h6>
      </div>
    </>
  );
};
export default Footer;
