import React from "react";
// import "./theme/css/App.css";
import Header from "./components/header/Header";
import Index from "./theme/Index";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <div>
      <Header />
      <Index />
      <Footer />
      <ScrollToTop smooth color="#ffffff" />
    </div>
  );
}

export default App;
