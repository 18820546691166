import React from 'react'
import "../../../theme/css/events.css"
import NextEvents from './NextEvents'
import Video from './Video'

const Events = () => {
  return (
    <>
    <div className='mainBanner'>
        <h1 className='text-white text-center'>
            Events
        </h1>
    </div>
    <NextEvents title="Prochains événements"/>
    <NextEvents title="Derniers événements"/>
    <Video />
    </>
  )
}

export default Events